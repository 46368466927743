#nav {
    margin: 0;
    padding: 5px 0;
    text-align: left;

    li {
        list-style: none;
        display: inline-block;
        color: #777;
    }

    a{
        text-decoration: none;
        font-size: 14px;
        color: #777;
        padding: 5px;
    }

    li a.active,
    li a:hover{
        background-color: #f5821f;
        color:#fff;
    }

}



@media screen and (min-width: 621px) {
    #nav {
        text-align: right;

        li {
            margin-right: 5px;
        }
    }
}