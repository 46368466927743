
.bvg-about {
    .bvg-banner {
        background-image: url(../public/images/banner.jpg);
        background-size: cover;
        width: 100%;
        background-position: center top;
        background-repeat: no-repeat;
        position: relative;
    }

    .bvg-background {
        padding: 10px;
    }
}