@charset "UTF-8";
.bvg-gallery-container {
  //padding: 0.9375rem 0;

  .bvg-gallery-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    > div {
      position: relative;
      cursor: pointer;
      border: 2px solid #f36523;
      background-color: #f36523;

      &.bvg-gallery-redirect {
        border-color: #f5821f;
      }

      &.no-image{
        height: 200px;
      }

      &.image-loaded {

        img {
          display: block;
          opacity: 1;
        }
      }
    }

    img {
      width: 100%;
      opacity: 0;
    }

    div:before, div:after {
      transition: 0.3s opacity ease;
      opacity: 0;
    }

    div:after {
      content: "↔";
      font-size: 80px;
      position: absolute;
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      color: #fff;
      left: 50%;
      top: 50%;
      display: block;
    }

    div:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(34, 34, 34, 0.5);
      display: block;
    }


    div:hover:before, div:hover:after {
      opacity: 1;
      transition: 0.3s opacity ease;
    }

    div.bvg-gallery-redirect:hover:before,
    div.bvg-gallery-redirect:hover:after,
    div.bvg-gallery-redirect:before,
    div.bvg-gallery-redirect:after  {
      display: none !important;
    }
  }
}

.bvg-modal-container {
  .bvg-modal-overlay {
    position: fixed;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;

    a {
      position: absolute;
      display: inline;
      color: #222;
      text-decoration: none;
      line-height: 36px;
      font-size: 30px;
      font-weight: lighter;
      background: #f36523;
      border-radius: 5px;
      height: 40px;
      width: 40px;
      text-align: center;
    }
  }

  .bvg-modal {
    position: fixed;
    z-index: 999;
    width: 50%;
    max-width: 800px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-style: initial;

    .bvg-modal-body {
      border: 5px solid #f36523;
      background-color: #f36523 ;
      position: relative;

      a {
        position: absolute;
        display: inline;
        color: #222;
        text-decoration: none;
        line-height: 36px;
        font-size: 30px;
        font-weight: lighter;
        background: #f36523;
        border-radius: 5px;
        height: 40px;
        width: 40px;
        text-align: center;
      }

      .bvg-modal-close {
        right: 0;
        top: 0;
        border-radius: 0 0 0 5px;
      }

      .bvg-modal-next,
      .bvg-modal-prev {
        right: 0;
        top: calc(50% - 25px);
        border-radius: 5px 0 0 5px;
        height: 50px;
        line-height: 40px;
        font-size: 60px;
      }

      .bvg-modal-prev {
        left: 0;
        right: auto;
        border-radius: 0 5px 5px 0;
      }
    }

    img {
      width: 100%;
    }

    @media (min-width: 20em) {
      & {
        width: 95%;
      }
    }
    @media (min-width: 34em) {
      & {
        width: 80%;
      }
    }
    @media (min-width: 60em) {
      & {
        width: 60%;
      }
    }
  }
}


@media screen and (max-width: 620px) {
  .bvg-gallery-container {
    .bvg-gallery-grid {
      > div {
        border: 0px;
      }
    }
  }
}

@media screen and (min-width: 621px) {
  .bvg-gallery-container {
    .bvg-gallery-grid {
      grid-template-columns: repeat(4, 1fr);

      img {
        height: 100%;
      }
    }
  }
}



