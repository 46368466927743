.bvg-home {
  text-align: left;
}

.bvg-vision-mission,
.bvg-home-gallery,
.bvg-updates {
  max-width: 100%;
  padding: 20px;
}

.bvg-vision-mission p {
  font-style: italic;
}

.bvg-updates {
  padding-top: 0px;
  padding-bottom: 0px;
}

.bvg-home-gallery {
  padding-top: 0px;

  a.bvg-main-img-redirect {
    background-color: #f5821f;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;

    p {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

hr {
  margin: 0;
  color: #f5821f;
}


.bvg-home h2 {
  color: #f5821f;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.2em;
  margin: 0;
  display: inline-block;
  border-bottom: 3px solid transparent;

}

.bvg-home h2:hover {
  text-decoration: none;
  text-decoration-color: green;
  border-bottom: 3px solid green;
}

.bvg-banner {
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

@media screen and (max-width: 360px) {
  .bvg-header {
    padding: 10px 5px 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .bvg-bjp-logo,
    .bvg-icon {
      width: 30px;
    }

    .bvg-title {
      margin: 0px;
    }
  }



}

@media screen and (max-width: 620px) {

  .bvg-banner {
    background-image: url(../public/images/bvg_amithshah.jpg);
    background-position: 70% 40%;
  }


  .bvg-home-gallery {

    .bvg-gallery-container {
      .bvg-gallery-grid {
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 10px;

        & > div {
          background-color: #f5821f;
          img {
            height: 110px;
          }
        }
      }
    }

    .bvg-gallery-redirect {
      display: table;
      height: 100%;

      a.bvg-main-img-redirect {
        display: table-cell;
        height: 90px;
        vertical-align: middle;
      }
    }

  }
}


@media screen and (min-width: 621px) {

  .bvg-banner {
    background-image: url(../public/images/bvg_amithshah.jpg);
    background-position: center center;
    background-size: cover;
    height: 450px;
  }
  .bvg-home-gallery {
    & > div {
      img {
        box-sizing: border-box;
      }

      &:first-child {
        img {
          &:first-child {
            border-left-width: 4px;
          }
        }
      }


      a.bvg-main-img-redirect {
        display: inline-block;
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-right-width: 4px;


        p {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          width: 100%;
        }
      }
    }

    &.bvg-activity > div{
      width: 50%;
    }
  }

}