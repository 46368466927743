.bvg-contact {
  padding: 10px;
  font-weight: 400;

  .bvg-contact-header {
    text-align: center;
    font-weight: 200;
    font-size: 46px;
    background: #f5821f;
    display: inline-block;
    line-height: 1.2em;
    width: 100%;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
    color: white;
  }

  .bvg-contact-container {
    h4 {
      margin: 0px;
    }

    p {
      margin-bottom: 10px;
      margin-top: 0px
    }
  }
}


@media screen and (min-width: 621px) {

  .bvg-contact .bvg-contact-header {
    font-size: 56px;

  }
  .bvg-contact-container {
    padding: 30px;
  }


}

