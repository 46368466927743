body {
  background: #f4f7f8 url(../public/bg.png);
  font: 14px/22px 'Roboto', Trebuchet MS, Helvetica, Arial, sans-serif;
  color: #404040;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0;
}


#bvg-pages {
  background-color: white;
  max-width: 1200px;
}



