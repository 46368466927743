div {
  box-sizing: border-box;
}

.bvg-home-header {
  padding: 20px;
  background-color: #f5821f;
  box-sizing: border-box;
}

.bvg-home-header p {
  display: inline;
  color: #fff;
  font-weight: 300;
  line-height: 1.2em;
}

@media screen and (max-width: 620px) {
  .bvg-banner {
    height: 250px;
  }

  .bvg-banner-container {
    .bvg-home-header {


      h1 {
        margin: 0px;
      }

      p {
        font-size: 28px;

        &.byline {
          display: inline-block;
          font-size: 22px;
          width: 100%;
          text-align: right;
          margin: 0px;
          padding-top: 10px;
        }
      }

    }
  }
}


@media screen and (min-width: 621px) {
  .bvg-home-header {
    display: inline-block;
    width: 100%;

    p {
      font-size: 42px;
      margin: 0px;
      display: block;


      &.byline {
        font-size: 28px;
        float: right;

      }
    }
  }
}
